import React from "react";
import "styles/pages/page-forecasting.scss";
import Seo from "components/Seo";
import Layout from "components/Layout";
import TitleWithLine from "../components/TitleWithLine";
import SubpageHeader from "../components/SubpageHeader";
import Banner from "../components/Banner";
import SectionBanner from "../components/SectionBanner";
import Accordion from "../components/Accordion";

const Forecasting = ({ pageContext }) => {
  const forecasting = pageContext.pageContent;

  const accordionItems = forecasting.limitlessAnalysingFlexibilityAccordion.map(
    (item, index) => {
      return {
        key: index,
        title: item.accordionTitle,
        desc: item.accordionText,
      };
    }
  );

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        subtitle={forecasting.bannerSubtext}
        image={require("assets/images/forecasting-bg.jpg").default}
      />
      <section className="section-forecasting">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-forecasting__inner">
                <TitleWithLine variant="bottom">
                  {forecasting.moreReliableAndPreciseTitle}
                </TitleWithLine>
                <div
                  dangerouslySetInnerHTML={{
                    __html: forecasting.moreReliableAndPreciseText,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={forecasting.moreReliableAndPreciseImage?.sourceUrl}
                alt=""
                className="img-fluid section-forecasting__img"
              />
            </div>
          </div>
        </div>
      </section>
      <Banner title={forecasting.divider1Text} />

      <section className="section-data">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-data__inner">
                <TitleWithLine variant="bottom">
                  {forecasting.limitlessAnalysingFlexibilityTitle}
                </TitleWithLine>
                <p className="section-data__text">
                  {forecasting.limitlessAnalysingFlexibilityText}
                </p>
              </div>
              <Accordion
                className="accordin"
                visibleOnStart={true}
                items={accordionItems}
              />
            </div>
            <div className="col-lg-5">
              <img
                src={forecasting.limitlessAnalysingFlexibilityImage?.sourceUrl}
                alt=""
                className="img-fluid section-forecasting__img"
              />
            </div>
          </div>
        </div>
      </section>

      <SectionBanner
        title={forecasting.divider2Title}
        description={forecasting.divider2Text}
        button="Contact"
        className=""
        link="/contact"
      />
    </Layout>
  );
};

export default Forecasting;
